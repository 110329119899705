<template>
    <div class="flex-between item-center" style="margin-bottom: 1%;">
        <div class="kcxq">课程详情</div>
        <el-button icon="arrow-left" plain @click.stop="handleBack">
            返回
        </el-button>
    </div>
    <div class="mt20 flex-between">
        <div class="flex-1 kcxqcontent">
            <div class="kcxqcontent-title text-hide">{{ ketitle }}</div>
            <div class="flex-start">
                <span class="kcxqcontent-name mr20">张学民</span>  &nbsp; &nbsp;
                <span class="kcxqcontent-name">2023-05-01</span>
            </div>
            <div class="kcxqcontent-line" />
            <el-scrollbar>
                <div class="kcxqcontent-text" v-if="type === 0">
                    2012年底以来，曹某铭设立江苏爱晚投资股份有限公司等系列公司（以下简称“爱晚系”公司），未经依法批准，以提供居家养老服务、进行艺术品投资等“老龄产业”为幌子，由组建的集资团队向有养老服务需求的不特定社会公众，特别是老年人进行虚假宣传，夸大经营规模、投资价值，并许诺给付年化收益率为8%-36%的高额回报。为了诱骗老年人参与投资，“爱晚系”公司集资团队成员采取了一系列手段，如免费发放鸡蛋、米、油等生活用品；吸引养老群体参加公司活动，向老年人宣讲老龄健康、老龄金融等公司六大板块，夸大宣传养老产业前景、规模；使用集资款设立养老社区、打造居家服务项目等，组织老年人参观、试住；邀请影视明星广告代言等。骗取宣传对象的信任后，“爱晚系”公司通过与其签订“居家服务合同”“艺术品交易合同”等方式收取集资款。
                    至2018年4月，“爱晚系”公司累计向11万余人吸收资金人民币（以下币种同）132.07亿余元。曹某铭将集资款主要用于支付集资参与人的本息，支付高管高额薪酬、奖金及销售团队薪酬、提成，个人支配使用、挥霍消费等，少部分用于对外投资。
                    2018年5月，曹某铭因担心资金链断裂，大量转移、隐匿资产，销毁、掩藏证据后逃往国外。案发时，给集资参与人造成本金损失46.98亿余元。
                    2019年11月26日，江苏省南京市中级人民法院以集资诈骗罪判处曹某铭无期徒刑，剥夺政治权利终身，并处没收个人全部财产。一审判决后，曹某铭上诉，2020年6月12日，江苏省高级人民法院裁定驳回上诉，维持原判。
                    社会公众在投资养老产业时，要根据公司企业经营状况、宣传项目是否真实存在、许诺的回报或分红是否超出合理范围、是否具有给付所承诺回报的盈利能力等方面，分辨融资活动是否属于吸收公众存款等公开募集资金行为甚至诈骗行为。
                    对于公开募集资金行为，还要进一步了解相关公司企业是否具有相应金融业务许可，判断是合法融资还是非法集资。没有取得相关金融业务许可，以入股、投资、预定等名义许诺还本付息或者给付其他投资回报，向不特定社会公众公开吸收资金的，就是非法集资。
                </div>
                <img class="kcxqcontent-img" v-if="type === 1" :src="class10" />
                <div class="kcxqcontent-div" v-if="type === 2">
                    <video class="video" :src="testvedio" autoplay controls controlslist="nodownload"
                           oncontextmenu="return false"></video>
                </div>
            </el-scrollbar>
        </div>
        <div class="tjkc">
            <div class="flex-between item-center" style="margin: 0px 12px">
                <div class="tjkc-title">推荐课程</div>
                <el-button icon="refresh" link>换一换</el-button>
            </div>
            <el-scrollbar>
                <div v-for="(item, index) in list" :key="index" >
                    <div class="demo-list-item-content flex-start" @click.stop="handleClickKc(mockDat.videoUrl[index],  mockDat.spjj[index])">
                        <img :src="mockDat.imgUrl[index]" class="demo-list-item-img" />
                        <div class="flex-column flex-between" style="height: 60px" >
                            <div class="demo-list-item-title">
                                {{ mockDat.spjj[index] }}
                            </div>
                            <div class="demo-list-item-name">张学民</div>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script setup>

import { defineComponent, reactive, toRefs, ref, onMounted, computed, onUnmounted, nextTick } from 'vue'

let mockDat = reactive({

    imgUrl: ['https://vodpub6.v.news.cn/yqfbzx-original/20240414/image/ab5c34e8-d90e-44dc-94a3-a4f6784fa321.jpg',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240302/image/560d92f0-f2ec-4363-b191-abbeca07f331.jpg',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240311/image/1f67bc65-4756-40e7-bccd-9eb89e514c84.jpg',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240314/image/e9559afd-04a0-44e0-af3c-1eb902d03d0c.jpg',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240226/image/34211269-b2ef-4836-8474-c3718e861740.jpg',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240204/image/73631b1e-cc31-44c9-a42e-5d1f73421d67.jpg',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240424/image/57717fd9-740c-43b0-92ff-b909ec5061ec.jpg',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240202/image/c6974b6d-d885-4ae0-8b78-7d8d69d7d8ef.jpg',
        'https://vodpub6.v.news.cn/yqfbzx-original/20230923/image/51c30cac-f702-4ce8-adac-dabde17c4239.png',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240424/image/faebb365-4bc8-48d4-b5f9-f32f51e61229.jpg',
        'https://vodpub6.v.news.cn/yqfbzx-original/20230920/image/d3a73327-d6d7-44d4-afb5-aa952a34dde4.jpg',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240426/image/7ad759a5-29f4-4d7e-b4a3-ab86069c734f.png'
    ],
    videoUrl: ['https://vodpub6.v.news.cn/yqfbzx-original/20240414/202404148e19d5578abf41abb82ee83dd030cafd_37b3c594affd4b608a1f85ab67e483f4.mp4',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240302/202403027050bafa7ff7424e9f851d743a9b429b_053176d1e6fb4a0986893fb513760e8e.mp4',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240311/2024031163f9e0b56ea943068b89ea5be9c0324b_bc4430a216f6465daf1a66cc6128be65.mp4',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240314/20240314f7a94965651b413297b1dfa39b843322_96eb861e0d754d0bbbe5b6fd6ba81199.mp4',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240226/202402264e9802b4be62494bbe8290f846ef3e93_c61ba08ce43a459f99840095ee77b816.mp4',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240204/2024020482fe68a4e0894908bd63fbcc48726c64_7da84fa59fe54d149cbfb40b398d34f9.mp4',
        ' https://vodpub6.v.news.cn/yqfbzx-original/20240424/2024042437a8eb4954f94955b73e7aafabb3766f_68af5b39a14c40f0b91fc368ebbef897.mp4',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240202/90dfbc04012f4209bf414070805fb3a1.mp4',
        'https://vodpub6.v.news.cn/yqfbzx-original/20230923/20230923ba005ec9379c4795ac11e74e8a1241f1_c3aaeaa18c2f44b2b922d42fb45ea16e.mp4',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240424/20240424fc180762bab14dd98c3d5880184ce430_1b432fc0a62c4770b16cb8100035e98d.mp4',
        'https://vodpub6.v.news.cn/yqfbzx-original/20230920/2023092042b4b542278c4425b444422af7b67ad5_e8a1673ec6a74d969f66bf12f7310460.mp4',
        'https://vodpub6.v.news.cn/yqfbzx-original/20240426/20240426709fc0786d884bdf8f366429ffe0a492_5aa0fcf29a2048729442ffb59de6b25d.mp4'
    ],
    //视频简介
    spjj:['总体国家安全观主题宣传片《护你安澜》','微视频｜巨变','信心·同心聚力','信心·破浪前行','微视频︱互联网之光','团圆年|龙吟中国春','中国航天日重磅微视频｜极目楚天 共襄星汉','团圆年｜我们的文化中国年：龙行龘龘','40所高校联动唱响杭州亚运应援曲《勇敢地》','筑梦天宫新航程 80后神十八乘组出征星海','中国的 | 景迈山申遗成功，云南这座古茶林什么来头？','中国的｜山西这个村竟然如此硬核？'],
})
const state = reactive({ list: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {} ,{}] });
const { list } = toRefs(state);
const props = defineProps({
    type: {
        typeof: Number,
        default: 0
    },
    title: {
        typeof: String,
        default: ''
    },
    videoUrl: {
        typeof: String,
        default: ''
    },
    dataList: {
        typeof: Array,
        default: []
    }
});


const emit = defineEmits();
const handleBack = () => {
    emit("handleBack");
};



let ketitle = ref('')
let testvedio = ref('');

const handleClickKc = (videoUrl,title) => {
    ketitle.value = title
    testvedio.value = videoUrl;
    window.scrollTo(0, 0)
};

onMounted(() => {
    ketitle.value = props.title
    testvedio.value = props.videoUrl;
});

</script>


<style lang="less" scoped>
@import './style.css';

.video {
    width: 100%;
    background-color: transparent;
}

.kcxq {
    height: 24px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #1d1e1f;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.kcxqcontent {
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    padding: 16px 20px;

    .kcxqcontent-title {
        height: 24px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        color: #1d1e1f;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .kcxqcontent-name {
        margin-top: 8px;
        height: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #86888c;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .kcxqcontent-line {
        width: 100%;
        height: 1px;
        background: #ebecf0;
        margin-top: 12px;
        margin-bottom: 20px;
    }

    .kcxqcontent-text {
        text-indent: 2em;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #585d62;
        line-height: 24px;
        text-align: justify;
        font-style: normal;
        text-transform: none;
    }

    .kcxqcontent-img {
        width: 100%;
        height: auto;
    }

    .kcxqcontent-div {
        position: relative;

        .kcxqcontent-back {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.6);
            border-radius: 4px 4px 4px 4px;

            .kcxqcontent-play {
                width: 60px;
                height: 60px;
            }
        }
    }
}

.tjkc {
    width: 336px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    padding: 16px 8px;
    margin-left: 28px;

    .tjkc-title {
        height: 24px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        color: #1d1e1f;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
}

.demo-list-item-content:hover {
    background-color: #f5f9ff;
}

.demo-list-item-content {
    cursor: pointer;
    width: 320px;
    border-radius: 4px 4px 4px 4px;
    padding: 12px;

    .demo-list-item-img {
        width: 100px;
        height: 70px;
        margin-right: 8px;
    }

    .demo-list-item-title {
        // height: 24px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #1d1e1f;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        text-transform: none;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }

    .demo-list-item-name {
        height: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #86888c;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 2%;
    }
}
</style>
