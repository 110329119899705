<template>
  <div v-if="total > pageSize" class="flex-between item-center">
    <div>
      <span class="total-label">共</span>
      <span class="total-value">{{ ` ${total || 0} ` }}</span>
      <span class="total-label">条数据</span>
    </div>
    <el-pagination
      background
      layout="prev, pager, next,jumper"
      :current-page="pageNum"
      :page-size="pageSize"
      :total="total"
      @current-change="handlePageChange"
    />
  </div>
</template>
<script setup>
defineProps({
  total: { type: Number, default: 0 },
  pageNum: { type: Number, default: 1 },
  pageSize: { type: Number, default: 10 }
});
const emit = defineEmits();
// 翻页
function handlePageChange(pageNum) {
  emit("handlePageChange", pageNum);
}
</script>
